import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import AuthLayout from "src/component/navigation/publicNavbar";

const ForgotPasswordScreen = lazy(
  () => import("src/pages/outerPages/forgot_password/forgotPasswordScreen")
);

const LoginScreen = lazy(
  () => import("src/pages/outerPages/login/loginScreen")
);
const ChangeForgotPasswordScreen = lazy(
  () =>
    import(
      "src/pages/outerPages/changePasswordOutSide/changeforgotPasswordScreen"
    )
);
const HomeScreen = lazy(() => import("src/pages/outerPages/home/home"));

const AuthRoute: React.FC = () => {
  return (
    <AuthLayout>
      <Suspense>
        <Routes>
          <Route path="/login" element={<LoginScreen />} />
          <Route path="/home" element={<HomeScreen />} />
          <Route path="/forgot_password" element={<ForgotPasswordScreen />} />
          <Route path="/" element={<HomeScreen />} />
          <Route
            path="/change_forgot_password"
            element={<ChangeForgotPasswordScreen />}
          />
        </Routes>
      </Suspense>
    </AuthLayout>
  );
};

export default AuthRoute;
