export const resources = {
  en: {
    mainMenuList: {
      Dashboard: "Dashboard",
      Users: "Users",
      Products: "Products",
      Search: "Search",
      Setting: "Setting",
    },
    subMenuList: {
      "Add Users": "Add Users",
      "Agent Onboarding": "Agent Onboarding",
      "DMT Setting": "DMT Setting",
      "AEPS Setting": "AEPS Setting",
      "Payout Setting": "Payout Setting",
      "Account Open Setting": "Account Open Setting",
      "Key Setting": "Key Setting",
      "Message Setting": "Message Setting",
      "SMS Setting": "SMS Setting",
      "A/c Opening": "A/c Opening",
      "Agent Ac Opening": "Agent Ac Opening",
      "Ac Open Report": "Ac Open Report",
      "Onboarding Report": "Onboarding Report",
      "A/c Statement Report": "A/c Statement Report",
      "Virtual Balance": "Virtual Balance",
      "Payout": "Payout",
      "Payout Beneficiary Report":"Payout Beneficiary Report",
       "Payout Transfer":"Payout Transfer",
      "Agent Balance":"Agent Balance",
      "User Registration":"User Registration",
      "MainMenuMaster":"MainMenuMaster",
      "MainMenuMasterMapping":"MainMenuMasterMapping",
    },
    groupRegisterScreen: {
      "Create Group": "Create Group",
      Address: "Address",
      EmailID: "Email ID (optional)",
      UserType: "User Type",
      MobileNumber: "Mobile Number",
      UserName: "User Name",
    },
    loginScreen: {
      "Welcome to NSDL Systems": "Welcome to NSDL PB BO Portal",
      "Please sign-in to your account": "Please sign-in to your account",
      MobileNumber: "Mobile Number",
      Password: "Password",
      "Forgot password": "Forgot password?",
      "Sign In": "Sign In",
    },
    forgotPassword: {
      "Forgot Password": "Forgot Password?",
      "Do you want to login": "Do you want to login?",
      "Return to Login": "Return to Login",
      "You'll receive information on how to get some rest":
        "You'll receive information on how to get some rest",
      MobileNumber: "Mobile Number",
    },
    accountMenu: {
      Profile: "Profile",
      "Change Password": "Change Password",
      Logout: "Logout",
      GroupName: "Group Name",
      Date: "Date",
    },
    public: {
      Home: "Home",
      About: "About",
      "Sign in": "Sign in",
    },
  },
  mr: {
    bachatGatReportScreen: {
      "BachatGat Report": "बचतगट अहवाल",
      DistrictName: "जिल्ह्याचे नाव",
      TalukaName: "तालुक्याचे नाव",
      "Select district": "जिल्हा निवडा",
      "Select taluka": "तालुका निवडा",
      GroupName: "गटाचे नाव",
      "Group Admin": "अध्यक्ष",
    },
  },
};
