import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import AuthInnerNavbar from "src/component/navbar/authInnerNavbar";
import { LOGOUT } from "src/component/helper/constants";
import { useDispatch } from "react-redux";

const AccountStatementReportScreen = lazy(
  () =>
    import(
      "src/pages/innerPages/report/accountStatementReport/accountStatementReportScreen"
    )
);
const AgentOnboardingReportScreen = lazy(
  () =>
    import(
      "src/pages/innerPages/report/agentOnboardingReport/agentOnboardingReportScreen"
    )
);

const AccountOpenReportScreen = lazy(
  () =>
    import(
      "src/pages/innerPages/report/accountOpenReport/accountOpenReportScreen"
    )
);

const AgentDashboardScreen = lazy(
  () => import("src/pages/innerPages/dashborad/agent/agentDashboardScreen")
);

const AEPSLandingScreen = lazy(
  () => import("src/pages/innerPages/aeps/aepsLandingScreen")
);

const AgentAccountOpen = lazy(
  () => import("src/pages/innerPages/agentAccountOpen/agentAccountOpen")
);
const BalanceReverseScreen = lazy(
  () => import("src/pages/innerPages/agentBalance/BalanceReverseScreen")
);

const AccountOpenSettingScreen = lazy(
  () =>
    import("src/pages/innerPages/accountOpenSetting/accountOpenSettingScreen")
);
const AepsTransactionScreen = lazy(
  () => import("src/pages/innerPages/aepsTransaction/aepsTransactionScreen")
);

const AepsTwoFaScreen = lazy(
  () => import("src/pages/innerPages/aepsTwoFA/aepsTwoFaScreen")
);

const AccountOpenScreen = lazy(
  () => import("src/pages/innerPages/accountOpen/accountOpenScreen")
);

const PendingRequestScreen = lazy(
  () => import("src/pages/innerPages/fund/pendingRequest/pendingRequestScreen")
);
const AepsScreen = lazy(
  () => import("src/pages/innerPages/aepsTerminalRegister/aepsScreen")
);

const AepsReportScreen = lazy(
  () => import("src/pages/innerPages/report/aepsReport/aepsReportScreen")
);

const DmtReportScreen = lazy(
  () => import("src/pages/innerPages/report/dmtReport/dmtReportScreen")
);

const DmtSenderSearchScreen = lazy(
  () => import("src/pages/innerPages/dmt/searchSender/dmtSenderSearch")
);
const AddBeneficiary = lazy(
  () => import("src/pages/innerPages/dmt/addBeneficiary/beneficiaryAdd")
);
const SplitDMTAmount = lazy(
  () => import("src/pages/innerPages/dmt/splitDMTAmount/splitDMTAmount")
);
const SenderOnboardingRequest = lazy(
  () =>
    import(
      "src/pages/innerPages/dmt/senderOnboardingRequest/senderOnboardingRequest"
    )
);
const SenderAadharKYC = lazy(
  () => import("src/pages/innerPages/dmt/senderAadharKYC/senderAadharKYC")
);
const RetailerDashboardScreen = lazy(
  () => import("src/pages/innerPages/dashborad/agent/agentDashboardScreen")
);
const OnboardingAgentScreen = lazy(
  () =>
    import(
      "src/pages/innerPages/onboarding/onboardingAgent/OnboardingAgentScreen"
    )
);
const OnboardingIndexScreen = lazy(
  () =>
    import(
      "src/pages/innerPages/onboarding/onboardingIndex/onBoardingIndexScreen"
    )
);
const ChangePasswordAddScreen = lazy(
  () => import("src/pages/innerPages/change_password/changePasswordAddScreen")
);
const Profile = lazy(() => import("src/pages/innerPages/profile/profile"));

const UserReportScreen = lazy(
  () => import("src/pages/innerPages/allUser/userReportScreen")
);

const QuickSearch = lazy(
  () => import("src/pages/innerPages/quickSearch/quickSearchScreen")
);

const AdminDashboardScreen = lazy(
  () => import("src/pages/innerPages/dashborad/admin/adminDashboardScreen")
);
const DistributorDashboardScreen = lazy(
  () =>
    import(
      "src/pages/innerPages/dashborad/distributor/distributorDashboardScreen"
    )
);
const VirtualBalanceScreen = lazy(
  () => import("src/pages/innerPages/virtualBalance/virtualBalanceScreen")
);
const AddBeneficiaryScreen = lazy(
  () =>
    import("src/pages/innerPages/payout/addBeneficiary/addBeneficiaryScreen")
);
const PayoutTransferScreen = lazy(
  () =>
    import("src/pages/innerPages/payout/payoutTransfer/payoutTransferScreen")
);
const UserOnboardingScreen = lazy(
  () =>
    import(
      "src/pages/innerPages/agentPersonalDetails/agentPersonalDetailsScreen"
    )
);

const KeySettingScreen = lazy(
  () => import("src/pages/innerPages/key_setting/keySettingScreen")
);

const MessageSettingAddScreen = lazy(
  () => import("src/pages/innerPages/message_setting/messageSettingScreen")
);

const SMSSettingAddScreen = lazy(
  () => import("src/pages/innerPages/sms/smsSettingAddScreen")
);
const DMTSettingScreen = lazy(
  () => import("src/pages/innerPages/dmt_setting/DMTSettingScreen")
);
const AepsSettingScreen = lazy(
  () => import("src/pages/innerPages/aepssetting/aepsSettingScreen")
);

const ForgotPasswordScreen = lazy(
  () => import("src/pages/outerPages/forgot_password/forgotPasswordScreen")
);

const ProductWiseCommissionScreen = lazy(
  () =>
    import(
      "src/pages/innerPages/productWiseCommission/productWiseCommissionScreen"
    )
);

const UserPlanMappingScreen = lazy(
  () => import("src/pages/innerPages/userPlanMapping/userPlanMappingScreen")
);
const UserRegistration = lazy(
  () =>
    import(
      "src/pages/innerPages/userRegistration/distributorRegistrationScreen"
    )
);

const FundRequestScreen = lazy(
  () => import("src/pages/innerPages/fundRequest/fundRequestScreen")
);

const CreatePlanScreen = lazy(
  () => import("src/pages/innerPages/planMaster/createPlan/addPlanScreen")
);
const PayoutBeneficiaryReportScreen = lazy(
  () =>
    import(
      "src/pages/innerPages/report/payoutBeneficiary/payoutBeneficiaryReportScreen"
    )
);

const MainMenuMasterScreen = lazy(
  () => import("src/pages/innerPages/mainMenuMaster/mainMenuMasterScreen")
);

const MainMenuMasterMappingScreen = lazy(
  () =>
    import(
      "src/pages/innerPages/mainMenuMasterMapping/mainMenuMasterMappingScreen"
    )
);

const MainRoute: React.FC = () => {
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(LOGOUT());
    LOGOUT();
  };

  return (
    <AuthInnerNavbar>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/Dashboard" element={<AdminDashboardScreen />} />
          <Route
            path="/DistributorDashboard"
            element={<DistributorDashboardScreen />}
          />
          <Route
            path="/RetailerDashboard"
            element={<RetailerDashboardScreen />}
          />
          <Route path="/AgentOnboarding" element={<OnboardingIndexScreen />} />
          <Route path="/Agent_edit" element={<OnboardingAgentScreen />} />
          <Route path="/AgentOnboarding" element={<OnboardingAgentScreen />} />
          <Route path="/KeySetting" element={<KeySettingScreen />} />
          <Route path="/MessageSetting" element={<MessageSettingAddScreen />} />
          <Route path="/SMSSetting" element={<SMSSettingAddScreen />} />
          <Route path="/DMTSetting" element={<DMTSettingScreen />} />
          <Route path="/AEPSSetting" element={<AepsSettingScreen />} />
          <Route
            path="/AccountOpenSetting"
            element={<AccountOpenSettingScreen />}
          />
          <Route path="/forgot_password" element={<ForgotPasswordScreen />} />
          <Route
            path="/AccountOpenSetting"
            element={<AccountOpenSettingScreen />}
          />
          <Route path="/changePassword" element={<ChangePasswordAddScreen />} />
          <Route path="/Search" element={<QuickSearch />} />
          <Route path="/AllUsers" element={<UserReportScreen />} />
          <Route path="/DMT" element={<DmtSenderSearchScreen />} />
          <Route path="/DMTReport" element={<DmtReportScreen />} />
          <Route path="/AEPS" element={<AEPSLandingScreen />} />
          <Route path="/AEPSReport" element={<AepsReportScreen />} />
          <Route path="/PendingRequest" element={<PendingRequestScreen />} />
          <Route path="/AccountOpen" element={<AccountOpenScreen />} />
          <Route path="/TwoFa" element={<AepsTwoFaScreen />} />
          <Route path="/AepsTransaction" element={<AepsTransactionScreen />} />
          <Route path="/AepsRegister" element={<AepsScreen />} />
          <Route path="/AgentAcOpening" element={<AgentAccountOpen />} />
          <Route path="/AgentDashboard" element={<AgentDashboardScreen />} />
          <Route path="/AgentBalance" element={<BalanceReverseScreen />} />
          <Route path="/AddBeneficiary" element={<AddBeneficiary />} />
          <Route path="/SplitDMTAmount" element={<SplitDMTAmount />} />
          <Route
            path="/MainMenuMasterMapping"
            element={<MainMenuMasterMappingScreen />}
          />
          <Route
            path="/SenderOnboardingRequest"
            element={<SenderOnboardingRequest />}
          />
          <Route path="/SenderAadharKYC" element={<SenderAadharKYC />} />

          <Route
            path="/OnboardingReport"
            element={<AgentOnboardingReportScreen />}
          />
          <Route
            path="/AccountOpenReport"
            element={<AccountOpenReportScreen />}
          />
          <Route
            path="/ProductWiseCommission"
            element={<ProductWiseCommissionScreen />}
          />
          <Route
            path="/AcStatementReport"
            element={<AccountStatementReportScreen />}
          />
          <Route path="/RequestRaise" element={<FundRequestScreen />} />
          <Route path="/UserPlanMapping" element={<UserPlanMappingScreen />} />
          <Route path="/CreatePlan" element={<CreatePlanScreen />} />
          <Route path="/VirtualBalance" element={<VirtualBalanceScreen />} />
          <Route path="/Payout" element={<PayoutTransferScreen />} />
          <Route path="/Profile" element={<Profile />} />
          <Route path="/UserRegistration" element={<UserRegistration />} />
          <Route path="/MainMenuMaster" element={<MainMenuMasterScreen />} />
          <Route
            path="/PayoutBeneficiaryReport"
            element={<PayoutBeneficiaryReportScreen />}
          />
          <Route
            path="/AddBeneficiaryScreen"
            element={<AddBeneficiaryScreen />}
          />
          <Route
            path="/Logout"
            element={<LogoutComponent onLogout={handleLogout} />}
          />
        </Routes>
      </Suspense>
    </AuthInnerNavbar>
  );
};

export default MainRoute;

interface LogoutComponentProps {
  onLogout: () => void;
}

const LogoutComponent: React.FC<LogoutComponentProps> = ({ onLogout }) => {
  React.useEffect(() => {
    onLogout();
  }, [onLogout]);

  return <div>Logging out...</div>;
};
