export enum TypeEnum {
    SelectType = "0",
    Text = "1",
    Number = "2",
    List = "3",
  }

  export type TypeList = {
    id: TypeEnum;
    title: string;
  };

export const Type: TypeList[] = [
    {
      id: TypeEnum.SelectType,
      title: "Select Type",
    },
    {
      id: TypeEnum.Text,
      title: "Text",
    },
    {
      id: TypeEnum.Number,
      title: "Number",
    },
    {
      id: TypeEnum.List,
      title: "List",
    },
  ];
  export const languageOptions = [
    { value: "en", label: "English" },
    { value: "mr", label: "मराठी" }, 
  ];

  
export const CriteriaList = [
  {
    id: '1',
    title: 'Sender Number',
  }, 
];