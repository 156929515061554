import { useState, useEffect } from "react";
import { SelectByUserID as WALLET_SELECT } from "src/component/api/userWalletMaster/userWalletMaster";
import { LOGOUT } from "src/component/helper/constants";
import { ToastError } from "src/component/uiElements/toast";

interface Wallet {
  name: string;
  balance: string;
}

const useWalletsBalance = () => {
  const [wallets, setWallets] = useState<Wallet[]>([]);

  const fetchWalletBalance = async () => {
    var response = await WALLET_SELECT(); 
    if (!response?.isAuthorized) {
      LOGOUT();
      return;
    }
    if (!response?.status) {
      let failResult = JSON.parse(response?.data!);
      ToastError(failResult.Message);
      return;
    }

    var data = JSON.parse(response?.data!);
    const walletData = data.map(
      (wallet: {
        WalletID: number;
        Name: string;
        Balance: number;
        ShortName: string;
      }) => ({
        name: wallet.Name,
        balance: `₹ ${wallet.Balance.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`,
      })
    );
    localStorage.setItem("Wallets", JSON.stringify(walletData));
    setWallets(walletData);
  };

  useEffect(() => {
    fetchWalletBalance();
  }, []);

  const updateBalance = async () => {
    fetchWalletBalance();
  };

  return { wallets, updateBalance };
};

export default useWalletsBalance;
