import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Button,
  IconButton,
  Grid,
  Box,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
  styled,
  BadgeProps,
  Badge,
  Link,
} from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
const Navbar: React.FC = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isMobileDrawerOpen, setIsMobileDrawerOpen] = useState(false);
  const { t } = useTranslation("public");

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [nestedAnchorEl, setNestedAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const [currentPath, setCurrentPath] = useState<string>("");

  useEffect(() => {
    setCurrentPath(window.location.pathname);
  }, []);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleNestedMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    if (nestedAnchorEl) {
      setNestedAnchorEl(null);
    } else {
      setNestedAnchorEl(event.currentTarget);
    }
  };

  const handleNestedMenuClose = () => {
    setNestedAnchorEl(null);
  };

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const toggleMobileDrawer = () => {
    setIsMobileDrawerOpen(!isMobileDrawerOpen);
  };

  const handleBack = () => {
    toggleMobileDrawer();
  };
  const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    "& .MuiBadge-badge": {
      right: -3,
      top: 20,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: "0 4px",
    },
  }));
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        zIndex: "1",
        position: "fixed",
        mx: "-8px",
      }}
    >
      <AppBar
        sx={{
          position: "relative",
          bgcolor: "#fff",
          color: "#000",
          boxShadow:
            "rgba(0, 0, 0, 0.2) 0px 14px 20px 0px, rgba(0, 0, 0, 0.1) 0px 0px 0px 1px",
          borderRadius: "120px",
          top: "20px",
          width: "50%",
          mx: "auto",
          "@media (max-width: 1311px)": {
            borderRadius: "30px",
            width: "78%",
          },
        }}
      >
        <Toolbar>
          <Grid sx={{ mr: "auto" }}>
            <Link href={"/"}>
              <img width="200px" src="logo.jpg" alt="Logo" />
            </Link>
          </Grid>

          <Grid sx={{ display: { xs: "none", md: "flex" } }}>
            <nav>
              <Button
                href="/"
                sx={{
                  color: "#000",
                  fontSize: "14px",
                  textTransform: "none",
                  bgcolor: "transparent",
                  borderRadius: "20px",
                  fontWeight: "600",
                  px: "12px",
                  mr: "6px",
                  "&:hover": {
                    color: "#000",
                    bgcolor: "rgb(241, 241, 241)",
                  },
                }}
              >
                {t("Home")}
              </Button>

              <Button
                href="/about"
                sx={{
                  color: "#000",
                  fontSize: "14px",
                  textTransform: "none",
                  bgcolor: "transparent",
                  borderRadius: "20px",
                  px: "12px",
                  mr: "6px",
                  fontWeight: "600",
                  "&:hover": {
                    color: "#000",
                    bgcolor: "rgb(241, 241, 241)",
                  },
                }}
              >
                {t("About")}
              </Button>
            </nav>
          </Grid>

          <Grid sx={{ ml: "auto" }}>
            <Button
              href="/login"
              size="large"
              sx={{
                color: "#fff",
                fontSize: "14px",
                textTransform: "none",
                bgcolor: "#000",
                borderRadius: "24px",
                px: "20px",
                fontWeight: "600",
                "&:hover": {
                  bgcolor: "#222",
                },
                "@media (max-width: 899px)": {
                  display: "none",
                },
              }}
            >
              {t("Sign in")}
            </Button>
          </Grid>

          <IconButton
            edge="end"
            aria-label="menu"
            onClick={toggleMobileDrawer}
            sx={{ display: { xs: "flex", md: "none", color: "#000" } }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Drawer
        anchor="left"
        open={isMobileDrawerOpen}
        onClose={toggleMobileDrawer}
        sx={{ display: { xs: "flex", md: "none" } }}
      >
        <Box
          sx={{
            width: 250,
            background: "rgba(255, 255, 255, 0.4)",
            backdropFilter: "blur(16px)",
          }}
        >
          <List>
            <ListItem
              component="button"
              onClick={handleBack}
              sx={{
                color: "#000",
                background: "transparent",
                borderRadius: "100px",
                mb: "10px",
                cursor: "pointer",
                border: "none",
                ml: "20px",
                pl: "10px",
                mt: "10px",
                "&:hover": { background: "rgb(241, 241, 241)" },
              }}
            >
              <ListItemText
                primary="Back"
                sx={{
                  color: "#000",
                  ml: "20px",
                }}
              />
            </ListItem>

            <Divider />

            <Grid>
              <Button
                href="/"
                sx={{
                  color: "#81859f",
                  fontSize: "14px",
                  textTransform: "none",
                  bgcolor: "transparent",
                  borderRadius: "20px",
                  px: "16px",
                  mt: "20px",
                  ml: "40px",
                  "&:hover": {
                    color: "#000",
                    bgcolor: "rgb(241, 241, 241)",
                  },
                }}
              >
                Home
              </Button>
            </Grid>

            <Grid>
              <Button
                href="/"
                sx={{
                  color: "#81859f",
                  fontSize: "14px",
                  textTransform: "none",
                  bgcolor: "transparent",
                  borderRadius: "20px",
                  px: "16px",
                  mt: "8px",
                  ml: "40px",
                  "&:hover": {
                    color: "#000",
                    bgcolor: "rgb(241, 241, 241)",
                  },
                }}
              >
                About
              </Button>
            </Grid>

            <Grid>
              <Button
                href="/"
                sx={{
                  color: "#81859f",
                  fontSize: "14px",
                  textTransform: "none",
                  bgcolor: "transparent",
                  borderRadius: "20px",
                  px: "16px",
                  mt: "8px",
                  ml: "40px",
                  "&:hover": {
                    color: "#000",
                    bgcolor: "rgb(241, 241, 241)",
                  },
                }}
              >
                Categories
              </Button>
            </Grid>

            <Grid>
              <Button
                href="/"
                sx={{
                  color: "#81859f",
                  fontSize: "14px",
                  textTransform: "none",
                  bgcolor: "transparent",
                  borderRadius: "20px",
                  px: "16px",
                  mt: "8px",
                  ml: "40px",
                  "&:hover": {
                    color: "#000",
                    bgcolor: "rgb(241, 241, 241)",
                  },
                }}
              >
                Schemes
              </Button>
            </Grid>

            <Grid>
              <Button
                href="/login"
                sx={{
                  color: "#81859f",
                  fontSize: "14px",
                  textTransform: "none",
                  bgcolor: "transparent",
                  borderRadius: "24px",
                  px: "16px",
                  mt: "10px",
                  ml: "40px",
                  "&:hover": {
                    bgcolor: "#000",
                    color: "#fff",
                  },
                }}
              >
                Sign in
              </Button>
            </Grid>
          </List>
        </Box>
      </Drawer>
    </Box>
  );
};

export default Navbar;
